export function Logo(props) {
  return (
    <img src="https://app.deeplawn.com/deeplawnlogo.png" alt="Deep Lawn logo" />
  )
}

export function ProLogo(props) {
  return (
    <img src="/deeplawnlogo-pro.png" alt="Deep Lawn logo" />
  )
}