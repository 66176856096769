'use client'
import { Fragment, useEffect } from 'react'
import Link from 'next/link'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'

import { Button } from './Button'
import { Container } from './Container'
import { Logo, ProLogo } from './Logo'
import React from 'react'
import { isPro, isRobinSite } from '../../redux/widget/widget.selectors'

function MobileNavLink({ href, children, onClick }) {
  return (
    <a href={href} onClick={onClick} className="block w-full p-2">
      {children}
    </a>
  )
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible"
      style={{ stroke: '#374151' }}
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0" style={{ backgroundColor: 'rgba(203, 213, 225, 0.7)' }} />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg text-gray-900 shadow-xl"
          >
            {({ close }) => (
              <>
                <MobileNavLink href="/#features" onClick={() => close()}>Features</MobileNavLink>
                <MobileNavLink href="/#pricing" onClick={() => close()}>Pricing</MobileNavLink>
                <MobileNavLink href="/about-us" onClick={() => close()}>About</MobileNavLink>
                <hr className="m-2 border-gray-300" />
                <MobileNavLink href="/sign-in" onClick={() => close()}>Sign in</MobileNavLink>
              </>
            )}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}
const buttonConstants = {
  LOGO: 'LOGO',
  PRICING: 'PRICING',
  CONTACT: 'CONTACT',
  ABOUT: 'ABOUT',
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP'
}
const showButton = (identifier?: string) => {
  if(identifier===buttonConstants.LOGO){
    return true
  }
  if(identifier===buttonConstants.PRICING){
    if (isPro(window)) {
      return false
    }
    return true
  }
  if(identifier===buttonConstants.CONTACT){
    if (isPro(window)) {
      return false
    }
    return true
  }
  if(identifier===buttonConstants.ABOUT){
    if (isPro(window)) {
      return false
    }
    return true
  }
  if(identifier===buttonConstants.SIGNIN){
    if (isPro(window)) {
      return false
    }
    return true
  }
  if(identifier===buttonConstants.SIGNUP){
    if (isPro(window)) {
      return false
    }
    return true
  }
  return true
}


export function Header() {
  if (typeof window == 'undefined') return <></>

  if(isRobinSite(window)) return
  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          {showButton(buttonConstants.LOGO) ? (
            <div className="flex items-center h-10 w-36 sm:w-44 md:w-52">
              <Link href="https://deeplawn.com/" aria-label="Home">
                {typeof window != 'undefined' ? window?.location?.origin?.includes(process.env.NEXT_PUBLIC_PRO_WEBSITE as string) ? <ProLogo /> : <Logo /> : null}
              </Link>
            </div>
          ) : null}

          {/* <div className="flex items-center gap-x-5 md:gap-x-8">
            {showButton(buttonConstants.PRICING) ? (
              <div className="hidden md:block">
                <Link href="/pricing">Pricing</Link>
              </div>
            ) : null}
            {showButton(buttonConstants.CONTACT) ? (
              <div className="hidden md:block">
                <Link href="/contact-us">Contact</Link>
              </div>
            ) : null}
            {showButton(buttonConstants.ABOUT) ? (
              <div className="hidden md:block">
                <Link href="/about-us">About</Link>
              </div>
            ) : null}

            {showButton(buttonConstants.SIGNIN) ? (
              <div className="hidden md:block">
                <Link href="/sign-in">Sign in</Link>
              </div>
            ) : null}
            {showButton(buttonConstants.SIGNUP) ? (
              <Button href="/pricing" color="blue">
                <span>
                  Sign up
                </span>
              </Button>
            ) : null}

            {
              !isPro(window)?(
                <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
              ):null
            }
          </div> */}
        </nav>
      </Container>
    </header>
  )
}
