import { eventFb, fbeventConstants } from "../../components/facebook-pixel/pixel-helpers";
import { eventMixPanel, mixPanelEventConstants } from "../../components/mix-panel-tracker/mixpanel-helpers";
import sendRequest from "../../utils/useAxios";
import { pricingPlanTypes } from "./pricing-plans.types";

export const getPricingPlan = (packageId) => async (dispatch) => {
  const { data } = await sendRequest('GET',`/pricing-plan/${packageId}`);
  if (!data.statusCode && data) {
    eventFb(fbeventConstants.ADD_TO_CART)
    eventMixPanel(mixPanelEventConstants.ADD_TO_CART)
    dispatch(setSuccess(pricingPlanTypes.SET_PRICING_PLAN, data));
  } else {
    // dispatch(setError(data?.message));
    dispatch(setError(data?.message));
  }
}

export const getPricingPlans = () => async (dispatch) => {
  const { data } = await sendRequest('GET',`/pricing-plan`);
  if (!data.statusCode && data) {
    let hdArray = data?.filter((each) => each?.isHD)
    let nonHdArray = data?.filter((each) => !each?.isHD)

    let sortedNonHdArray = nonHdArray.sort((a, b) => {
      return a.price - b.price;
    });
    let sortedHdArray = hdArray.sort((a, b) => {
      return a.price - b.price;
    });

    const joinedArray = [].concat(sortedNonHdArray, sortedHdArray);
    dispatch(setSuccess(pricingPlanTypes.SET_PRICING_PLANS, joinedArray));
  } else {
    dispatch(setError(data?.message));
  }
}

export const setSuccess = (action, payload) => {
  return {
    type: pricingPlanTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: pricingPlanTypes.SET_ERROR_PRICING_PLAN,
    payload,
  }
}