import Image from 'next/image'

import { Container } from './Container'
import backgroundImage from '../../public/images/background_faqs.jpg'
import React from 'react'

const faqs = [
  [
    {
      question: 'What is deep lawn?',
      answer:
        'Deep Lawn enables lawn care and landscaping companies to sell their services online in less than one minute by combining machine learning, geospatial data, and payment processing into an all-in-one e-commerce tool.',
    },
    {
      question: 'Who is deep lawn for?',
      answer: 'Deep lawn is for care, landscaping, pest control and other outdoor service companies.',
    },
    {
      question: 'How easy is deep lawn to set up?',
      answer:
        'Deep Lawn sign up and integration take less than two hours. Your company could begin automatically estimating your customers lawns and receiving payments through deeplawn later today.',
    },
  ],
  [
    {
      question: 'How much does deep lawn cost?',
      answer:
        'Deep Lawn begins at $40 a month for smaller companies because you only pay for what you use. The largest companies can go to $500 and more.',
    },
    {
      question:
        'How easy is it to set up deep lawn’s e-commerce tool on my website?',
      answer:
        'Simple and easy. We have tutorial videos to walk you through the step-by-step process and a live and local customer service team to answer any questions real time.',
    },
  ],
  // [
  //   {
  //     question: 'How do you generate reports?',
  //     answer:
  //       'You just tell us what data you need a report for, and we get our kids to create beautiful charts for you using only the finest crayons.',
  //   },
  //   {
  //     question: 'Can we expect more inventory features?',
  //     answer: 'In life it’s really better to never expect anything at all.',
  //   },
  //   {
  //     question: 'I lost my password, how do I get into my account?',
  //     answer:
  //       'Send us an email and we will send you a copy of our latest password spreadsheet so you can find your information.',
  //   },
  // ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-gray-50 py-20 sm:py-32"
    >
      <img
        className="absolute max-w-none w-full h-full -top-52 left-1/4"
        src='/images/background-faqs.jpg'
        alt=""
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-2"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="mb-0 mt-4 text-sm text-gray-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      <p className='mb-0 mt-24 text-7xl text-center'>Built with ❤️ in Texas</p>
      </Container>
    </section>
  )
}
